import React from 'react';
import { Card, CardContent } from './ui/card';
import { Star } from 'lucide-react';

const TestimonialCard = ({ name, role, company, quote, darkMode }) => (
  <Card className={`${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-100'} border-none transition-all duration-300 hover:-translate-y-1 transform`}>
    <CardContent className="p-6">
      <div className="flex items-center mb-4">
        {[...Array(5)].map((_, i) => (
          <Star key={i} className="w-5 h-5 text-yellow-400 mr-1" />
        ))}
      </div>
      <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} italic mb-4`}>"{quote}"</p>
      <div className="text-right">
        <p className={`font-semibold ${darkMode ? 'text-blue-400' : 'text-blue-600'}`}>{name}</p>
        <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{role}, {company}</p>
      </div>
    </CardContent>
  </Card>
);


export default TestimonialCard;

import React from 'react';
import { Card, CardContent } from './ui/card';

const ProjectCard = ({ name, description, linkcivitai, linkhf, darkMode }) => (
  <Card className={`${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-100'} border-none transition-all duration-300 hover:-translate-y-1 transform`}>
    <CardContent className="p-6">
      <h4 className={`text-xl font-semibold mb-2 ${darkMode ? 'text-white' : 'text-gray-800'}`}>{name}</h4>
      <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} mb-4`}>{description}</p>
      <div className="flex justify-between">
        {linkcivitai && (
          <a href={linkcivitai} target="_blank" rel="noopener noreferrer" className={`${darkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'} transition-colors`}>
            Civitai
          </a>
        )}
        {linkhf && (
          <a href={linkhf} target="_blank" rel="noopener noreferrer" className={`${darkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'} transition-colors`}>
            Hugging Face
          </a>
        )}
      </div>
    </CardContent>
  </Card>
);


export default ProjectCard;
